const links = [
  {
    label: "Youtube",
    url: "https://www.youtube.com/channel/UCULzSTxbJ4TmzjwcuAnzi_A",
  },
  {
    label: "Twitter",
    url: "https://twitter.com/lexandresl",
  },
  {
    label: "Github",
    url: "https://github.com/Alexandresl",
  },
]

export default links
