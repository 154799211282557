import { Youtube } from "styled-icons/boxicons-logos/Youtube"
import { Twitter } from "styled-icons/fa-brands/Twitter"
import { Github } from "styled-icons/fa-brands/Github"

const Icons = {
  Youtube,
  Twitter,
  Github,
}

export default Icons
