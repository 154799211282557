const links = [
  {
    label: "Home",
    url: "/",
  },
  {
    label: "Sobre mim",
    url: "/about/",
  },
  {
    label: "Portfolio",
    url: "/portfolio/",
  },
]

export default links
